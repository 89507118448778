import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const List = (props) => {
  const [list, setList] = useState(props.location.list);
  const [displayList, setDisplayList] = useState("");
  const [openDialog,setOpenDialog] = useState(false)
  const [questions,setQuestions] = useState([]);
  const [questions1,setQuestions1] = useState([]);

  useEffect(() => {
    let tempDisplayList = [];

    list.forEach((item) => {
      tempDisplayList.push(
        <div
          style={{
            marginRight: "10px",
            marginBottom: "10px",
            padding: "10px",
            border: "1px solid black",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div>Subject: {item.subject}</div>
          <div>Chapter: {item.chapter}</div>
          <div>Concept: {item.concept}</div>
          <div>Sub-Concept: {item.subConcept}</div>
          
          <div >No. of Practice Questions: {item.practiceQuestions.length}</div>
          <div >No. of Solved Questions: {item.solvedQuestions.length}</div>

          <div
            style={{color:'white',background:'blue',width:'200px',textAlign:'center',cursor:'pointer'}}
            onClick={()=>{
              setOpenDialog(true)
              setQuestions(item.practiceQuestions)
              setQuestions1(item.solvedQuestions)
            }}
          >
            View Questions
          </div>
          {/* <div>No. of Quick Questions: {item.quickQuestions.length}</div> */}
        </div>
      );
    });

    setDisplayList(tempDisplayList);
  }, [list]);

  

  console.log("questions",questions)
  return (
    <>
    <Dialog 
                    open={openDialog}
                    onClose={() => (setOpenDialog(false))}
                    maxWidth="lg"
                >
                    <DialogTitle 
                        style={{
                            backgroundColor:"rgb(27, 29, 61)",
                            color:"white",
                            fontWeight:"bold"
                        }}
                    > Questions Selected</DialogTitle>
                    <DialogContent>
                    
                        {questions?
                            <div style={{ padding: "10px",display:'flex'}}>
                              <div style={{width:'50%'}}>
                                <p>practice questions</p>
                                {questions.map((ques, index) => (
                                    <div key={index} style={{background:"blue",borderRadius:"10px",margin:"20px",padding:"10px",color:"white"}}>
                                        <label style={{color:"black",width:"100%"}}>
                                            {/* <input type="checkbox" checked={!!selectedQuestions.find(item => item._id === ques._id)} value={!!selectedQuestions.find(item => item._id === ques._id)} onChange={(event) => handleInputChange(event.target.value, ques)}/> */}
                                            <strong> Question {index + 1}</strong>
                                            <div style={{backgroundColor:"white",padding:"10px",borderRadius:"10px",marginTop:"10px"}}>
                                            {ReactHtmlParser(ques[ques.questionType] && ques[ques.questionType].question)}</div>
                                        </label>
                                    </div>
                                ))}
                              </div>
                              <div style={{width:'50%'}}>
                                <p>solved questions</p>
                                  {questions1.map((ques, index) => (
                                    <div key={index} style={{background:"blue",borderRadius:"10px",margin:"20px",padding:"10px",color:"white"}}>
                                        <label style={{color:"black",width:"100%"}}>
                                            {/* <input type="checkbox" checked={!!selectedQuestions.find(item => item._id === ques._id)} value={!!selectedQuestions.find(item => item._id === ques._id)} onChange={(event) => handleInputChange(event.target.value, ques)}/> */}
                                            <strong> Question {index + 1}</strong>
                                            <div style={{backgroundColor:"white",padding:"10px",borderRadius:"10px",marginTop:"10px"}}>
                                            {ReactHtmlParser(ques[ques.questionType] && ques[ques.questionType].question)}</div>
                                        </label>
                                    </div>
                                ))}
                              </div>
                                
                            </div>
                        :null}
                    </DialogContent>
                    
                    <DialogActions>
                        
                    </DialogActions>
                </Dialog>
    <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
      {displayList}
    </div>
    </>
  );
};

export default List;
