import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../context/authContext/authContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthencated, loading } = authContext;
  const [loading1, setLoading1] = useState(true);

  useEffect(() => {
    console.log("1st  re render");
    if (isAuthencated === null || (!isAuthencated && !loading)) {
      localStorage.setItem("loginRedirect", "true");
      setLoading1(false);
    } else {
      if (localStorage.getItem("loginRedirect") === "true") {
        localStorage.setItem("loginRedirect", "false");
      } else {
        localStorage.setItem("prevPath", "");
      }
      setLoading1(false);
    }
  }, []);

  console.log(
    "1stt ",
    localStorage.getItem("prevPath"),
    " 22nd ",
    localStorage.getItem("loginRedirect")
  );
  console.log("1stt auth ", isAuthencated, " loading ", loading , " final ",(!isAuthencated && !loading));

  // useEffect(()=>{
  //     if(isAuthencated === true){
  //         localStorage.setItem('prevPath',"")
  //     }
  // },[isAuthencated])

  return (
    <>
      {!loading1 ? (
        <Route
          {...rest}
          render={(props) =>
            isAuthencated === null || (!isAuthencated && !loading) ? (
              <>
                {console.log("private ", props)}
                {localStorage.setItem("prevPath", props.location.pathname)}
                <Redirect to="/login" />
              </>
            ) : (
              <>
                {localStorage.getItem("prevPath").length === 0 ? (
                  <>
                    {console.log("private2 ", props)}
                    <Component {...props} />
                  </>
                ) : (
                  // <Redirect
                  //     {...props}
                  //     to={localStorage.getItem("prevPath")}
                  // />
                  <>
                    {console.log(
                      "private3 ",
                      props,
                      "prevpath",
                      localStorage.getItem("prevPath")
                    )}
                    <Second
                      props={props}
                      to={localStorage.getItem("prevPath")}
                    />
                  </>
                )}
              </>
            )
          }
        />
      ) : null}
    </>
  );
};

const Second = ({ to, props }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    localStorage.setItem("prevPath", "");
    setLoading(false);
  }, []);

  return <>{!loading ? <Redirect {...props} to={to} /> : null}</>;
};

export default PrivateRoute;
