import React, { Component } from "react";
import Divider from '@material-ui/core/Divider';

export default class DropdownOption extends Component {
    render() {
        let { value, id, dropdownOption, handleClick, className } = this.props;
        return (
            
            <li 
                style={{liststyletype:"none"}}
                onClick={() => handleClick(id, value)}
                key={value}
                className={className}
            > 
                {dropdownOption}
                <Divider dark />
            </li>
            
            
            
        );
    }
}
