import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/authContext/authContext";
import axiosInstance from "../../utils/axios";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { loadUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    loadUser();
    history.push("/admin/video");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-container">
      <div className="main"></div>
    </div>
  );
};
export default Home;
