import { API_URL } from "./constants";

var axios = require("axios");

var axiosInstance = axios.create({
    baseURL: API_URL,
    // baseURL: "http://15.206.93.247",
      //baseURL: "http://localhost:1234",
      	// baseURL: "https://testapi.acadza.in/",
});

export default axiosInstance;
