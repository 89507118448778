import React, { useReducer } from "react";
import authReducer from "./authReducer";
import AuthContext from "./authContext";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
  LOAD_SUBJECT_DATA,
} from "../types";
import axiosInstance from "../../../utils/axios";

const initialize = () => {
  if (localStorage.stateData) {
    const tempStateData = JSON.parse(localStorage.stateData);
    return {
      ...tempStateData,
      error: null,
    };
  } else {
    return {
      token: localStorage.getItem("token"),
      isAuthencated: null,
      loading: true,
      user: null,
      username: null,
      error: null,
      subjectData: {
        Math: [],
        Physics: [],
        Chemistry: [],
        "Inorganic Chemistry": [],
        "Physical Chemistry": [],
        "Organic Chemistry": [],
      },
    };
  }
};

const AuthState = (props) => {
  // const intialState = {
  //   token: localStorage.getItem('token'),
  //   isAuthencated: null,
  //   loading: true,
  //   user: null,
  //   error: null
  // }

  const intialState = initialize();

  const [state, dispatch] = useReducer(authReducer, intialState);

  // Load User
  const loadUser = async () => {
    console.log("loadeduser", localStorage.getItem("user"));
    if (localStorage.user) {
      dispatch({
        type: USER_LOADED,
        payload: localStorage.getItem("user"),
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
  // const loadUser = async () => {
  //   // if (localStorage.token) {
  //   //   setAuthToken(localStorage.token)
  //   // }
  //   try {
  //     const config = {
  //       header: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //     const data={userid:localStorage.user}
  //     const res = await axiosInstance.post('/user/me',data,config)
  //     console.log('loadUser',res.data);
  //     dispatch({
  //       type: USER_LOADED,
  //       payload: res.data.user
  //     })
  //   } catch (err) {
  //     dispatch({
  //       type: AUTH_ERROR,
  //     })
  //   }
  // }

  //Register User
  const register = async (formData) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axiosInstance.post("/user/register", formData, config);

      console.log(res);

      // dispatch({
      //   type: REGISTER_SUCCESS,
      //   payload: res.data
      // })
      // loadUser()

      if (res.data.isSuccessful) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
        loadUser();
      } else {
        dispatch({
          type: REGISTER_FAIL,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        // payload: err.response.data.error
      });
    }
  };

  //login user

  const login = async (formData) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axiosInstance.post("/user/login", formData, config);
      console.log("user data", res.data);
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: res.data
      // })
      // loadUser()

      if (res.data["isSuccessful"]) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        loadUser();
      } else {
        console.log(res.data["message"]);
        dispatch({
          type: LOGIN_FAIL,
          // payload: err.response.data.msg
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        // payload: err.response.data.msg
      });
    }
  };
  const setError = (err) => {
    dispatch({
      type: REGISTER_FAIL,
      payload: [{ msg: err }],
    });
  };
  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const loadSubData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const subjects = {
      Math: [],
      Physics: [],
      Chemistry: [],
      "Inorganic Chemistry": [],
      "Physical Chemistry": [],
      "Organic Chemistry": [],
    };

    const SubKeys = Object.keys(subjects);

    for (var i = 0; i < SubKeys.length; i++) {
      try {
        const res = await axiosInstance.post(
          "https://content.acadza.in/theory/listSubConcepts/",
          {
            subject: SubKeys[i],
          },
          config
        );

        console.log("loading res ", res.data, " ", SubKeys[i]);

        subjects[SubKeys[i]] = res.data;
      } catch (err) {
        console.log("error fetching ", SubKeys[i]);
      }
    }

    console.log("done fetching");

    dispatch({
      type: LOAD_SUBJECT_DATA,
      payload: subjects,
    });
  };
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthencated: state.isAuthencated,
        user: state.user,
        username: state.username,
        error: state.error,
        loading: state.loading,
        subjectData: state.subjectData,
        register,
        login,
        loadUser,
        logout,
        clearErrors,
        setError,
        loadSubData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
