import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import AuthContext from "../context/authContext/authContext";
import { withStyles } from "@material-ui/styles";
import AcadzaLogo from "../Logos/gear_and_logo.svg";
import Diagonal from "../Logos/Rectangle 15.svg";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  firstcontainer: {
    backgroundColor: "blue",
  },
  maincontainer: {
    fontFamily: "poppins, sans-serif",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    position: "absolute",
    // marginLeft:"170px",
    // marginRight:"174px",
    // marginTop:"149px",
    // marginBottom:"149px",
    // paddingLeft:"56px"
  },

  landingPagebtn1: {
    zIndex: "100",
    position: "absolute",
    top: "6%",
    left: "5%",
    background: `url(${Diagonal})`,
    width: "7px",
    height: "40px",
    borderRadius: "50px",
    transform: "rotate(-45deg)",
    backgroundColor: "#262C44",
    outline: "none",
    cursor: "pointer",
  },
  landingPagebtn2: {
    zIndex: "100",
    position: "absolute",
    top: "6%",
    left: "5%",
    background: `url(${Diagonal})`,
    width: "7px",
    height: "40px",
    borderRadius: "50px",
    transform: "rotate(45deg)",
    backgroundColor: "#262C44",
    cursor: "pointer",
    outline: "none",
  },
  Acadza: {
    width: "235px",
    height: "174px",
    background: `url(${AcadzaLogo})`,
    backgroundSize: "100% 100%",
    outline: "none",
    backgroundRepeat: "no-repeat",
    marginBottom: "50px",
  },
  signupbtn: {
    position: "absolute",
    top: "18%",
    left: "58%",
    outline: "none",
    textTransform: "uppercase",
    lineHeight: "54px",
    color: "#5843BE",
    fontSize: "36px",
    fontWeight: 600,

    "&:focus, &:hover, &:visited, &:link, &:active": {
      outline: "none",
      textDecoration: "none",
    },
  },
  loginBox: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    width: "100%",
    height: "100%",
  },
  loginHeading: {
    marginBottom: "24px",
    textAlign: "center",
    outline: "none",
    fontWeight: 600,
  },
  inputBox: {
    width: "100%",
    height: "60px",
    borderRadius: "30px",
    backgroundColor: "#ECECEC",
    border: "2px solid #ECECEC",
    padding: "5px 22px",
    outline: "none",
    "&:focus": {
      outline: "none",
      border: "2px solid #778DE5",
    },
    fontFamily: "poppins, sans-serif",
    fontSize: "15px",
  },
  signedinField: {
    fontFamily: "poppins",
    fontWeight: "bold",
    outline: "none",
    marginLeft: "20px",
  },
  signedinCheckbox: {
    position: "relative",
    marginRight: "8px",
    outline: "none",
    top: "3px",
    height: "18px",
    width: "19px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
  },
  checkboxLabel: {
    color: "rgba(123, 123, 123, 1)",
    size: "18px",
    fontSize: "15px",
    fontWeight: "400",
  },
  signedinQuestionMark: {
    marginLeft: "5px",
    borderRadius: "50%",
    textAlign: "center",
    width: "25px",
    height: "25px",
    backgroundColor: "#778DE5",
    color: "white",
    lineHeight: "25px",
    outline: "none",
    cursor: "help",
  },
  loginbtn: {
    width: "100%",
    height: "60px",
    borderRadius: "30px",
    backgroundColor: "#5843BE",
    color: "white",
    boxShadow: "0px 5px 6px #352A6A",
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: "20px",
    outline: "none",
    border: 0,

    "&:hover": {
      outline: "none",
      backgroundColor: "#0056b3",
    },
    "&:disabled": {
      outline: "none",
      backgroundColor: "#c5cedb",
    },
  },
  form: {
    width: "36.8%",
    maxWidth: "544px",
    minWidth: "240px",
    height: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  UpperLoginBox: {
    height: "32%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  // forget: {

  //   transform: "translate(-50px, 11px)",
  //   position: "absolute",
  // },
});

const Login = (props) => {
  const { login, isAuthencated, error, clearErrors } = useContext(AuthContext);
  const { classes } = props;
  const history = useHistory();
  useEffect(() => {
    // if (isAuthencated) {
    //     props.history.push("/");
    //     clearErrors();
    // } else {
    //     clearErrors();
    // }
    // eslint-disable-next-line
    if (props.opacity)
      setBackgroundopacity({ backgroundColor: "rgba(0,0,0,0.2)" });
  }, [isAuthencated, props.history]);

  useEffect(()=>{
    if(isAuthencated){
      history.push("/admin/video");
    }
  },[isAuthencated])

  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const { username, password } = user;

  const [backgroundopacity, setBackgroundopacity] = useState({
    backgroundColor: "rgba(255,255,255,1)",
  });

  const onchange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    if (error !== null) {
      clearErrors();
    }
  };
  const onsubmit = (e) => {
    e.preventDefault();
    login({
      username,
      password,
    });
    clearErrors();
    localStorage.getItem("user");
    if (localStorage.user) {
      history.replace("/");
    }
  };

  const onClose = () => {
    if (props.handleLoginClose) {
      props.handleLoginClose();
    } else {
      history.replace("/");
    }
  };
  return (
    <div className={classes.firstcontainer}>
      <div className={classes.maincontainer}>
        {/* <Link onClick={() => props.onClose(false)}>
                    <div className={classes.landingPagebtn1}></div>
                    <div className={classes.landingPagebtn2}></div>
                </Link>{" "} */}
        {/* &#10060; */}
        {/* <h4> <Link to="/register" className={classes.signupbtn}>SIGNUP</Link></h4> */}
        <main className={classes.loginBox}>
          <div className={classes.UpperLoginBox}>
            <div className={classes.Acadza}></div>
            <h1 className={classes.loginHeading}>Login</h1>
          </div>
          <form onSubmit={onsubmit} className={classes.form}>
            <input
              type="username"
              value={username}
              onChange={onchange}
              name="username"
              className={classes.inputBox}
              required
              placeholder="Enter your username"
            />
            <input
              type="password"
              value={password}
              onChange={onchange}
              name="password"
              className={classes.inputBox}
              required
              placeholder="Enter your password"
            />

            <div className={classes.signedinField}>
              <input
                type="checkbox"
                value="signedin"
                name="signedin"
                className={classes.signedinCheckbox}
              />
              <label for="signedin" className={classes.checkboxLabel}>
                Keep me signed in
              </label>
              <label for="signedin" className={classes.signedinQuestionMark}>
                ?
              </label>
            </div>
            <input type="submit" className={classes.loginbtn} value="Login" />
          </form>
        </main>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
