// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".videoQues_QuesDialog__3O7_z > div > div {\n  max-width: 710px !important;\n  /* background-color: black !important; */\n}\n\n.videoQues_DropdownButtons__1i-3f{\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.videoQues_logoutbutton__3PUul{\n text-align: center;\n width: 150px;\n height: 35px;\n background-color: #5843BE;\n color: white;\n padding:5px 5px;\n border-radius: 20px;\n box-shadow: 0 8px 6px -6px grey;\n font-family: \"poppins\";\n font-size: 17px;\n cursor: pointer;\n}\n\n.videoQues_main__2CvvM{\n  display:flex;\n  justify-content: space-between;\n  padding:15px 120px 0px 120px;\n  background-color: #F6F7F8;\n}\n\n.videoQues_header__1zACA{\n  font-size: 26px;\n  font-family: \"Poppins\";\n  color: #5843BE;\n  \n}", "",{"version":3,"sources":["webpack://src/components/Admin/videoQues.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,YAAY;CACZ,yBAAyB;CACzB,YAAY;CACZ,eAAe;CACf,mBAAmB;CACnB,+BAA+B;CAC/B,sBAAsB;CACtB,eAAe;CACf,eAAe;AAChB;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,cAAc;;AAEhB","sourcesContent":[".QuesDialog > div > div {\n  max-width: 710px !important;\n  /* background-color: black !important; */\n}\n\n.DropdownButtons{\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.logoutbutton{\n text-align: center;\n width: 150px;\n height: 35px;\n background-color: #5843BE;\n color: white;\n padding:5px 5px;\n border-radius: 20px;\n box-shadow: 0 8px 6px -6px grey;\n font-family: \"poppins\";\n font-size: 17px;\n cursor: pointer;\n}\n\n.main{\n  display:flex;\n  justify-content: space-between;\n  padding:15px 120px 0px 120px;\n  background-color: #F6F7F8;\n}\n\n.header{\n  font-size: 26px;\n  font-family: \"Poppins\";\n  color: #5843BE;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuesDialog": "videoQues_QuesDialog__3O7_z",
	"DropdownButtons": "videoQues_DropdownButtons__1i-3f",
	"logoutbutton": "videoQues_logoutbutton__3PUul",
	"main": "videoQues_main__2CvvM",
	"header": "videoQues_header__1zACA"
};
export default ___CSS_LOADER_EXPORT___;
