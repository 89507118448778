import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import List from "./components/Admin/list";
import SubConcept from "./components/Admin/subConcept";
import VideoQues from "./components/Admin/videoQues";
import AuthState from "./components/context/authContext/AuthState";
import PrivateRoute from "./components/routing/PrivateRoute";
import Home from "./components/StudentModule/Home";
import Login from "./components/StudentModule/Login";

function App() {
  return (
    <AuthState>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/admin/video" component={VideoQues} />
          <PrivateRoute exact path="/admin/subConcept" component={SubConcept} />
          <PrivateRoute exact path="/admin/list" component={List} />
        </Switch>
      </Router>
    </AuthState>
  );
}

export default App;
